import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { Overlay } from '@app/components';
import { useOnEscape } from '@app/hooks';
import cx from 'classnames';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import './popover.scss';

export type PopoverProps = PropsWithChildren<
  {
    className?: string;
    open: boolean;
    onClose?: () => void;
    customCloseBtn?: boolean;
  } & HTMLAttributes<HTMLDivElement>
>;

export const Popover: FC<PopoverProps> = ({ className, children, open, onClose, customCloseBtn, ...rest }) => {
  useOnEscape(onClose);

  return (
    <Overlay className="popover-overlay" visible={open} onClick={onClose}>
      <div className={cx(className, 'popover')} {...rest}>
        {children}
        {!customCloseBtn && (
          <button className="popover__close-btn" type="button" onClick={onClose}>
            <CrossIcon />
          </button>
        )}
      </div>
    </Overlay>
  );
};
