import { Badge, BurgerMenu } from '@app/components';
import { JoinButton } from '@app/components/join-button/JoinButton';
import { ReactNode, useContext } from 'react';
import { SiteMap } from '@app/constants';
import { ymGoal } from '@app/utils';
import { Link } from 'react-router-dom';
import { useEnhancedTranslation } from '@app/hooks';
import { HeaderContext } from '../header.context';
import { ChangeLangMenu } from './ChangeLangMenu';

import '../header.scss';

type MobileHeaderProps = {
  navbar: ReactNode;
};

export const MobileHeader = ({ navbar }: MobileHeaderProps): JSX.Element => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'header' });
  const { isBadgeVisible, setIsBadgeVisible } = useContext(HeaderContext);

  return (
    <BurgerMenu
      className="header__burger"
      headerClassName="header__burger-header"
      contentClassName="header__burger-content"
      visibleBadge={isBadgeVisible}
      headerContent={
        <>
          <JoinButton />
          {isBadgeVisible && (
            <Link
              to={SiteMap.ACCOUNT}
              onClick={() => {
                ymGoal('connecttop');
                setIsBadgeVisible?.(false);
              }}
            >
              <Badge variant="drawer" content={1} />
            </Link>
          )}
        </>
      }
    >
      {navbar}
      <div className="header__controls">
        <ChangeLangMenu />
        <Link to={SiteMap.ACCOUNT} onClick={() => ymGoal('perare')} className="header__control">
          {t('account')}
        </Link>
        {/* <Link to={SiteMap.MAP} onClick={() => ymGoal('depstolay')} className="header__control">
          {t('mallMap')}
        </Link> */}
        <Link to={SiteMap.SEARCH} className="header__control">
          {t('search')}
        </Link>
      </div>
    </BurgerMenu>
  );
};
