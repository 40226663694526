import { useEffect, useMemo, FC, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useExternalControllerSubscribeEmailMutation } from '@app/api/external-api';
import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow.svg';
import { ReactComponent as LogoIcon } from '@app/assets/icons/logo.svg';
import { ReactComponent as MetasharksLogo } from '@app/assets/icons/metasharks.svg';
import { NavBar } from '@app/components';
import { SiteMap } from '@app/constants';
import { NAVBAR_DATA } from '@app/data/navbar.data';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import { localizeDayOfWeek } from '@app/i18n';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import './footer.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { documentsTaggedApi } from '@app/api/tagged/documentsApi';
import { toPdf } from '@app/api/mappers/media.mappers';

export const Footer: FC = () => {
  const { useLazyDocumentsControllerGetQuery } = documentsTaggedApi;
  const [getDocuments, { data: documents }] = useLazyDocumentsControllerGetQuery();

  const { isMobile } = useMedia();
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'footer' });
  const [email, setEmail] = useState<string>();
  const [emailLabel, setEmailLabel] = useState<string>('yourEmailLabel');
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [postEmail] = useExternalControllerSubscribeEmailMutation();

  const navbarData = NAVBAR_DATA;

  const socialLinksNode = (
    <address className="footer__social-links">
      {[
        { name: 'contacts.socialMedia.telegram', link: 'contacts.socialMedia.telegramLink' },
        { name: 'contacts.socialMedia.vkontakte', link: 'contacts.socialMedia.vkontakteLink' },
        { name: 'contacts.socialMedia.youtube', link: 'contacts.socialMedia.youtubeLink' },
      ].map(({ name, link }) => (
        <a key={name} href={tRoot(link) || ''} className="footer__social-link">
          {tRoot(name)}
        </a>
      ))}
    </address>
  );

  // const loyalityProgramTerms = useMemo(() => {
  //   const doc = documents?.find((el) => el.type === 'loyalty_program_terms');
  //   return doc ? toPdf(doc).url : '';
  // }, [documents]);

  // const privacyPolicy = useMemo(() => {
  //   const doc = documents?.find((el) => el.type === 'privacy_policy');
  //   return doc ? toPdf(doc).url : '';
  // }, [documents]);

  const loyalytyProgramDiscount = useMemo(() => {
    const doc = documents?.find((el) => el.type === 'loyalyty_program_discount');
    return doc ? toPdf(doc).url : '';
  }, [documents]);

  const infoLinksNodes = useMemo(
    () =>
      [
        { name: 'info.privacyPolicy.title', link: SiteMap.PRIVACY_POLICY },
        { name: 'info.faq.title', link: SiteMap.FAQ },
        { name: 'info.loyalityTerms.title', link: SiteMap.INFO_PROGRAM_TERMS },
        { name: 'footer.loyalityProgram', link: loyalytyProgramDiscount, args: { target: '_blank' } },
      ].map(({ name, link, args }) => (
        <a key={name} href={link} {...args} className="footer__info-link">
          {tRoot(name)}
        </a>
      )),
    [loyalytyProgramDiscount],
  );

  const submitEmail = async (): Promise<void> => {
    if (!email) return;
    const resp = await postEmail({ emailDto: { email } });
    setEmail(undefined);
    emailInputRef.current && (emailInputRef.current.value = '');
    setEmailLabel('error' in resp ? 'yourEmailError' : 'yourEmailSubmitted');
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <footer className="footer" data-testid="footer">
      <div className="footer__grid">
        <div className="footer__grid-item footer__grid-item--1">
          <Link to="/">
            <LogoIcon className="footer__logo" data-testid="logo" />
          </Link>
        </div>
        <div className="footer__grid-item footer__grid-item--2">
          <NavBar
            data={navbarData}
            className="footer__navbar"
            listClassName="footer__navbar-list"
            itemClassName="footer__navbar-item"
          />
        </div>
        <div className="footer__grid-item footer__grid-item--3 footer__your-email">
          <label className="footer__email-label" htmlFor="your-email">
            <input
              ref={emailInputRef}
              className="footer__email-input"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailLabel(e.target.value ? 'yourEmailConfirmation' : 'yourEmailLabel');
              }}
              onKeyDown={({ key }) => key === 'Enter' && submitEmail()}
              name="your-email"
              placeholder={t('yourEmail') || ''}
              type="email"
            />
            <span className="footer__email-label-text">{t(emailLabel)}</span>
            {(isMobile || !!email) && (
              <button onClick={submitEmail} type="submit" aria-label="submit email" className="footer__email-submit">
                <ArrowIcon className="footer__email-submit-icon" />
              </button>
            )}
          </label>
        </div>
        {!isMobile && (
          <div className="footer__grid-item footer__grid-item--4 footer__social">
            <p className="footer__subtitle">{t('socialMedia')}</p>
            {socialLinksNode}
          </div>
        )}
        <div className="footer__grid-item footer__grid-item--5 footer__contacts">
          <p className="footer__subtitle">{t('contacts')}</p>
          <address>{tRoot('contacts.address')}</address>
          <address>
            <a href={tRoot('contacts.phoneLink') || ''}>{tRoot('contacts.phone')}</a>
            <br />
            <a href={tRoot('contacts.emailLink') || ''}>{tRoot('contacts.email')}</a>
          </address>
          {isMobile && socialLinksNode}
        </div>
        <div className="footer__grid-item footer__grid-item--6 footer__opening-hours">
          <p className="footer__subtitle">{t('openingHours.title')}</p>
          <p className="footer__text">
            <span>
              {localizeDayOfWeek(1)} — {localizeDayOfWeek(6)}
            </span>
            <br />
            <strong>
              <Trans
                t={t}
                i18nKey="openingHours.hours"
                values={{
                  from: '10:00',
                  to: '22:00',
                }}
                // eslint-disable-next-line react/jsx-key
                components={[<time dateTime="10:00" />, <time dateTime="22:00" />]}
              />
            </strong>
          </p>
          <p className="footer__text">
            <span>{localizeDayOfWeek(0)}</span>
            <br />
            <strong>
              <Trans
                t={t}
                i18nKey="openingHours.hours"
                values={{
                  from: '11:00',
                  to: '22:00',
                }}
                // eslint-disable-next-line react/jsx-key
                components={[<time dateTime="11:00" />, <time dateTime="22:00" />]}
              />
            </strong>
          </p>
        </div>
        <div className="footer__grid-item footer__grid-item--7 footer__parking-prices">
          <p className="footer__subtitle">{t('parking.title')}</p>
          <p className="footer__text">
            <span>
              <Trans
                t={t}
                i18nKey="parking.hours"
                values={{
                  from: '8:00',
                  to: '23:59',
                }}
                // eslint-disable-next-line react/jsx-key
                components={[<time dateTime="08:00" />, <time dateTime="23:59" />]}
              />
            </span>
            <br />
            <strong>{t('parking.dayPrice')}</strong>
          </p>
          <p className="footer__text">
            <Trans
              t={t}
              i18nKey="parking.hours"
              values={{
                from: '0:00',
                to: '7:59',
              }}
              // eslint-disable-next-line react/jsx-key
              components={[<time dateTime="00:00" />, <time dateTime="07:59" />]}
            />
            <br />
            <strong>{t('parking.nightPrice')}</strong>
          </p>
        </div>
      </div>
      <nav className="footer__info">
        <div className="footer__info-links">{infoLinksNodes}</div>
        <div>
          <a id="copyright-link" target="_blank" rel="noreferrer" href="https://metasharks.ru">
            <MetasharksLogo data-testid="metasharks-logo" />
          </a>
          <Tooltip
            anchorSelect="#copyright-link"
            className="footer__copyright-tooltip"
            content={t('copyrightTooltip') || ''}
          />
        </div>
      </nav>
    </footer>
  );
};
