import { InteractiveDiv } from '@app/components';
import cx from 'classnames';
import { gsap } from 'gsap';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import './overlay.scss';

export type OverlayProps = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  containerEl?: HTMLElement;
  visible: boolean;
};

export const OVERLAY_ANIMATION_DURATION = 150;

export const Overlay: FC<OverlayProps> = ({ className, children, onClick, containerEl = document.body, visible }) => {
  const timelineRef = useRef<gsap.core.Timeline>();
  const overlayRef = useRef(null);

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (event.target === overlayRef.current) {
        event.stopPropagation();
        onClick?.();
      }
    },
    [onClick],
  );

  useLayoutEffect(() => {
    timelineRef.current = gsap.timeline({ paused: true }).fromTo(
      overlayRef.current,
      {
        autoAlpha: 0,
      },
      {
        duration: OVERLAY_ANIMATION_DURATION / 1000,
        autoAlpha: 1,
      },
    );

    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
  }, []);

  useEffect(() => {
    visible ? timelineRef.current?.play() : timelineRef.current?.reverse();
  }, [visible]);

  useLayoutEffect(() => {
    visible ? document.body.classList.add('scroll-disabled') : document.body.classList.remove('scroll-disabled');
    return () => document.body.classList.remove('scroll-disabled');
  }, [visible]);

  return createPortal(
    <InteractiveDiv role="presentation" ref={overlayRef} onClick={handleClick} className={cx(className, 'overlay')}>
      {children}
    </InteractiveDiv>,
    containerEl,
  );
};
